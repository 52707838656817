import React from 'react';

class Buttons extends React.Component {
  addButton(btn) {
    let buttons = document.getElementById('buttons');
    let index = buttons.children.length;


    if (index >= 24) {
      btn.setAttribute('disabled', true);
    }
    else {
      btn.removeAttribute('disabled');
    }

    buttons.insertAdjacentHTML('beforeend',
      `<div id="button-${index}" class="embed-group" style="display: inline-block; margin-top: 0; width: 50%">
         <div class="button">
           <input type="text" name="button-${index}:label" maxlength="80" placeholder="Button ${index + 1} Label" />
           <textarea name="button-${index}:url" maxlength="1024" rows="2" placeholder="Button ${index +1} URL"></textarea>
         </div>
       </div>`);

    let removeBtn = document.getElementById('btn-removeField');
    removeBtn.removeAttribute('disabled');
  }

  removeButton(btn) {
    let buttons = document.getElementById('buttons');
    let index = buttons.children.length;

    if (index - 1) {
      btn.removeAttribute('disabled');
    }
    else {
      btn.setAttribute('disabled', true);
    }

    let button = document.getElementById(`button-${index - 1}`);
    if (button) {
      button.parentNode.removeChild(button);
    }

    let addBtn = document.getElementById('btn-addButton');
    addBtn.removeAttribute('disabled');
  }

  render() {
    return(
      <div className = "embed-group embed-buttons">
        <div id = "buttons"></div>
        <div className = "embed-group-controls">
          <button
            id = "btn-addButton"
            type = "button"
            onClick = {event => this.addButton(event.target)}
          >
            <span role = "img" aria-label = "Add Emoji">➕</span>&ensp;
            Add Button
          </button>
          <button
            id = "btn-removeButton"
            type = "button"
            onClick = {event => this.removeButton(event.target)}
          >
            <span role = "img" aria-label = "Remove Emoji">➖</span>&ensp;
            Remove Button
          </button>
        </div>
      </div>
    );
  }
}

export default Buttons;