import React from 'react';
class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageURL: ''
    };
  }

  updateImageURL(event) {
    this.setState({
      imageURL: event.target.value
    });
  }

  render() {
    return(
      <div className = "embed-group embed-image">
        <div className = "embed-image-url">
          <input
            type = "url"
            name = "image:url"
            placeholder = "Image URL"
            value = {this.state.imageURL}
            onChange = {event => this.updateImageURL(event)}
          />
        </div>
      </div>
    );
  }
}

export default Image;
