import React from 'react';

class Output extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: ""
    };
  }

  generateJSON() {
    let form = document.getElementById('embed-builder');

    let result = "";
    let embed = Boolean(false)
    let content = form.elements['content'].value;
    if (content) {
      result += `{content: ${content}}`
    }
    let title = form.elements['title'].value;
    if (title) {
      if (!embed) {
        result += `{embed}`
        embed = Boolean(true)
      }
      result += `{title: ${title}}$v`
    }

    let description = form.elements['description'].value;
    if (description) {
      if (!embed) {
        result += `{embed}`
        embed = Boolean(true)
      }
      result += `{description: ${description}}$v`
    }

    let url = form.elements['url'].value;
    if (url) {
      if (!embed) {
        result += `{embed}`
        embed = Boolean(true)
      }
      result += `{url: ${url}}$v`
    }
    
    let author_url = form.elements['author:url'].value;
    let author_icon_url = form.elements['author:icon_url'].value;
    let author_name = form.elements['author:name'].value;
    
    if (author_name) {
      let author = `author: ${author_name}`
      
      if (author_icon_url) {
        author +=  ` && ${author_icon_url}`
      }

      if (author_url) {
        if (!author_icon_url) {
          author += ` && none`
        }
        author += ` && ${author_url}`
      }
      if (!embed) {
        result += `{embed}`
        embed = Boolean(true)
      }
      result += `{${author}}$v`
    }

    let fields = document.getElementById('fields').children;
    if (fields.length) {
      for (let i = 0; i < fields.length; i++) {
        let field_name = form.elements[`field-${i}:name`].value;
        let field_value = form.elements[`field-${i}:value`].value;
        let field_inline = form.elements[`field-${i}:inline`].checked;

        if (field_name && field_value) {
          let field = `field: ${field_name} && ${field_value}`

          if (field_inline) {
            field += " && true"
          }
          else {
            field += " && false"
          }
          if (!embed) {
            result += `{embed}`
            embed = Boolean(true)
          }
          result += `{${field}}$v`
        }
      }
    }

    let thumbnail = form.elements['thumbnail:url'].value;
    if (thumbnail) {
      if (!embed) {
        result += `{embed}`
        embed = Boolean(true)
      }   
      result += `{thumbnail: ${thumbnail}}$v`
    }

    let image = form.elements['image:url'].value;
    if (image) {
      if (!embed) {
        result += `{embed}`
        embed = Boolean(true)
      }  
      result += `{image: ${image}}$v`
    }

    let footer_icon_url = form.elements['footer:icon_url'].value;
    let footer_text = form.elements['footer:text'].value;
    
    if (footer_text) {
      let footer = `footer: ${footer_text}`
      
      if (footer_icon_url) {
        footer +=  ` && ${footer_icon_url}`
      }
      if (!embed) {
        result += `{embed}`
        embed = Boolean(true)
      }
      result += `{${footer}}$v`
    }

    let color = form.elements['color'].value;
    if (color) {
      if (embed) {
        color = `${parseInt(color, 10).toString(16).padStart(6, '0')}`
        if (color !== "#000000") {
          result += `{color: #${color}}$v`
        }  
      }
    }

    let buttons = document.getElementById('buttons').children;
    if (buttons.length) {
      for (let i = 0; i < buttons.length; i++) {
        let button_label = form.elements[`button-${i}:label`].value;
        let button_url = form.elements[`button-${i}:url`].value;

        if (button_label && button_url) {
          let button = `button: ${button_label} && ${button_url}`
          if (!embed) {
            result += `{embed}`
            embed = Boolean(true)
          }  
          result += `{${button}}$v`
        }
      }
    }

    if (result.includes("{embed}")) {
      result = result.slice(0, -2)
    }
    else {
      result = result
    }
    document.getElementById('json-output').innerHTML = result

    let jsonOutput = document.getElementById('json-output').innerHTML;
    jsonOutput = jsonOutput.replace(/"([\w]*)":/g, '<span class="highlight key">"$1"</span>:');
    jsonOutput = jsonOutput.replace(/(\d*),/g, '<span class="highlight number">$1</span>,');
    jsonOutput = jsonOutput.replace(/: (true|false)/g, ': <span class="highlight boolean">$1</span>');
    jsonOutput = jsonOutput.replace(/: "(.*?)"/g, ': <span class="highlight string">"$1"</span>');
    document.getElementById('json-output').innerHTML = jsonOutput;
  }

  copyJSON() {
    let textarea = document.createElement('textarea')
  
    textarea.id = 'temp_element'
    textarea.style.height = 0

    document.body.appendChild(textarea)

    textarea.value = document.getElementById("json-output").innerText

    let selector = document.querySelector('#temp_element')

    selector.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
  }

  render() {
    return(
      <div className = "cell">
        <div id = "output-container">
          <div className = "controller">
            <button onClick = {() => this.generateJSON()}>
              <span role="img" aria-label="Gear Emoji">⚙</span>&ensp;Generate Embed Code
            </button>
            <button onClick = {() => this.copyJSON()}>
              <span role="img" aria-label="Copy Emoji">🔗</span>&ensp;Copy Embed Code
            </button>
          </div>
          <div className="output">
            <pre><div id="json-output" readOnly>{this.state.result}</div></pre>
          </div>
        </div>
      </div>
    );
  }
}

export default Output;
